<template>
    <v-row no-gutters class="d-block">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                            <v-btn color="success" small v-if="permisos[2]" outlined @click="cursosNuevo()">
                                <v-icon>add</v-icon>
                                Nuevo
                            </v-btn>
                        </v-col>

                        <v-col cols="6" sm="3" md="2" lg="2" xl="2" class="">
                            <v-btn color="info" small outlined @click="cursosGet()">
                                <v-icon>refresh</v-icon>
                                Refrescar
                            </v-btn>
                        </v-col>

                        <v-col cols="6" sm="3" md="2" lg="2" xl="2" class="pt-0">
                            <v-select
                                hide-details
                                v-model="filtros.anyo"
                                :items="catAnyos"
                                item-text="anyo"
                                item-value="cat_anyo_id"
                                @input="cursosBuscar()"
                            />
                        </v-col>

                        <v-col cols="6" sm="3" md="2" lg="2" xl="2" class="pt-0">
                            <v-select
                                hide-details
                                v-model="filtros.unidad_operativa_id"
                                :items="unidadesOperativas"
                                item-text="unidad_operativa"
                                item-value="unidad_operativa_id"
                                @input="cursosBuscar()"
                            />
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="pt-0 mt-0">
                            <v-text-field
                                hide-details
                                v-model="search"
                                label="Buscar por Estudiante, Curso, Registro y pulse en el botón."
                                clearable>
                                <template v-slot:append>
                                    <v-btn
                                        color="secondary" small outlined
                                        @click="cursosBuscar()">
                                        <v-icon>search</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        dense
                        class="elevation-3"
                        :headers="tableHeaders"
                        :items="cursos"
                        :loading="loading"
                        item-key="curso_id"
                        fixed-header
                        height="65vh"
                        :hide-default-footer="true"
                        disable-pagination
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td class="text-left">
                                    {{ item.numero_identidad }}
                                </td>
                                <td class="text-left">{{ item.nombres_apellidos }}</td>
                                <td class="text-left">{{ item.registro }}</td>
                                <td class="text-left">{{ item.curso }}</td>
                                <td class="text-left">{{ item.fecha_inicio | fechaESP }} al {{ item.fecha_finalizacion | fechaESP }}</td>
                                <td class="text-left">{{ item.tutor }}</td>
                                <td class="text-left">{{ item.importacion_numero }}</td>
                                <td class="text-center">
                                    <v-btn color="info" small outlined @click="cursoEdit(item)" title="Editar" v-if="permisos[3]">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:no-data>
                            <v-alert dense type="warning" outlined class="text-center mt-3">
                                No existen registros.
                            </v-alert>
                        </template>
                    </v-data-table>
                    <Pagination :pagination="pagination" @setCambiarPagina="setCambiarPagina" v-if="pagination.total"/>
                </v-card-text>
            </v-card>
        </v-col>

    </v-row>

</template>

<script>
import Auth from "@/services/auth";
const Pagination = () => import("@/pages/Components/Pagination.vue");

export default {
    name: "CursosLista",
    components: {Pagination},
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            permisos: [],
            search: "",
            pagination: {},
            pageActual: 1,
            rowsPage: this.$store.state.arrayRowsPages[0],
            loading: false,
            catAnyos: [],
            unidadesOperativas: [],
            cursos: [],
            tableHeaders: [
                {
                    text: 'Identidad',
                    value: 'numero_identidad',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Nombres Participante',
                    value: 'nombres_apellidos',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Registro',
                    value: 'registro',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Curso',
                    value: 'curso',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Período',
                    value: 'fecha_inicio',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Tutor',
                    value: 'tutor',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'N.° Import.',
                    value: 'importacion_numero',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Acciones',
                    align: 'center',
                    sortable: false,
                },
            ],
            filtros: {
                aprobado: this.getParams.aprobado,
                anyo: null,
                unidad_operativa_id: null,
            }
        }
    },

    methods: {
        cursosNuevo() {
            this.$router.push({name: 'CursosNuevo'});
        },
        cursosGetFiltros() {

            window.axios.get('/cursos/get_filtros', {}).then(async response => {
                if (response.data.success) {

                    this.catAnyos = response.data.data.catAnyos;
                    this.unidadesOperativas = response.data.data.unidadesOperativas;

                    // Despues de asignar los filtros, vamos a traer los datos
                    await this.cursosGet();

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                })
        },
        cursosBuscar() {
            this.pageActual = 1;
            this.cursosGet();
        },
        cursosGet() {
            this.loading = true;
            let params = {filtros: this.filtros, busqueda: this.search, page: this.pageActual, rowsPage: this.rowsPage};

            window.axios.get('/cursos', {params}).then(response => {
                if (response.data.success) {

                    let data = response.data.data;
                    this.cursos = data.cursos.data;
                    this.pagination = data.pagination;

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        cursoEdit(curso) {
            if (typeof curso == "object") {
                let token = window.btoa(curso.curso_id);
                this.$router.push({name: 'CursosEditar', query: {token}});
            } else {
                this.$toast.error("Parametro no valido.");
            }
        },
        setCambiarPagina(data) {
            this.pageActual = data.page;
            this.rowsPage = data.rowsPage;
            this.cursosGet();
        },
    },
    created() {

        Auth.checkPermisos(this.$route.path).then((res) => {
            this.permisos = res;

            if (!this.permisos[2]) {
                this.regresar();
            }

            this.cursosGetFiltros();
        });
    }
}
</script>

<style scoped>

</style>
